.headerbar{
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction:row;
    height: 50px;
   
    box-shadow: 0px 0px 4px 2px #dbdada;
    background-color: #fff;
 }
 .gobackicon{
     display: flex; 
     height: 100%;
     width: 10%;
     align-items:center;
     justify-content: center;
 
 }
 .searchicon-page{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 25px;
    right: 0px;
    top: 0px;
    color: rgb(97, 96, 96);
 }
 .backicon-page{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 20px;
    right: 0px;
    top: 0px;
    color: rgb(0, 0, 0);
 }
 .bartitel{
     display: flex;
     height: 100%;
     width: 90%;
     overflow: scroll;
     align-items:center;
     justify-content:left;
 }
 .booklisttitel{
     letter-spacing: 1px;
     font-size:18px;
     text-transform:uppercase;

     
 }
 .pageheadertitel{
    font-size: 17px;
    font-weight: 500;
    font-family: var(--font);
    letter-spacing: 1px;
    color: #000;

 }
 