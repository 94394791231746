
.dashboard{
    margin-top: 0px;
    scroll-behavior: smooth;
    background-color: #fff;
}
/* .tabbarrcontiner{
    height:60px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color:#fff;

} */
.tabbarr{
    position:fixed;
    display: flex;
    width: 100%;
    height: 50px;
    bottom: 0px;
    align-items:center;
    justify-content:space-around;
    background-color:#D6D6D6;
    z-index: 2;

    /* background-image: linear-gradient(180deg, rgb(245, 182, 122),rgba(247, 241, 241, 0),); */
    /* border-radius: "10px"; */
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
    /* border-bottom: 1px solid rgba(202, 202, 202, 0.932);  */
   }
   .tabbar_itemm{
    color: black;
    display: flex;
    height: 100%;
    width: 100%;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    font-size: 13px;
    letter-spacing: 0px;
    background-color:#F36C23;
    font-family: var(--font);

   }
   .fliterdiv{
    display:flex;
    width: 100%;
    margin-top: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

   }
   .filter-titel{
    font-family: var(--font);
    font-size: 14px;
    color: black;
    border-bottom: solid #F36C23 2px;
   }
   .filtericon{
    outline: 5px solid #fff;
    position: absolute;
    padding: 10px;
    border-radius: 20px;
    bottom: -20px;
    color: #fff;
    background-color:#F36C23;
   }
   .lable-filter{
    font-weight: bold;
    font-family: var(--font);
    color: black;
    font-size: 13px;

   }
   .sort{
    font-family: var(--font);
    color: rgb(107, 106, 106);
    font-size: 12px;
   }
   .inthome-titel{
    color: black;
    font-weight: 800;
    font-size: 15px;
    letter-spacing: 1px;
    font-family: var(--font);

   }
   .lable-filter-sub{
    margin-left: 5px;
    font-size: 13px;
    letter-spacing: 1px;
    font-family: var(--font);
   }
   .line{
    height: 2px;
    width: 60px;
    background-color: #000;
   }
   .orgname{
    color: #f67225;
    font-weight: 800;
    font-size: 14px;
    text-align: center;
    letter-spacing: 1px;
    font-family: var(--font);
   }
   .list-result{
    display: flex;
    width: 100%;
    flex-direction: row;
    height: 30px;
    padding: 10px;
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;
   }
   .resulttext{
    color: #111111;
    letter-spacing: 1px;
    font-family: var(--font);
    font-weight: bold;
   }
   .cleartext{
    background-color: #f67225;
    padding: 4px;
    letter-spacing: 1px;
    font-family: var(--font);
    font-size: 10px;
    color: #fff;
   }
   .filtercheck{
    margin-top: 5px !important;
   }
   
   /* .fooder{
       margin-top: 10px;
       display: flex;
       width: 100%;
       height: 100px;
       align-items: center;
       justify-content: center;
       flex-direction: column;
       background-image: linear-gradient(to right, rgba(247, 241, 241, 0), rgb(245, 182, 122));

    } */
    /* .fooder_continer{
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-image: linear-gradient(180deg,rgba(247, 241, 241, 0), rgb(245, 182, 122));
    } */
    .counter{
        display: flex;
        width: 100%;
        background-color: #ffffff;
        padding: 5px;
    }
    .counter_title{
        display: flex;
        height: 30px;
        width: 100%;
        font-size: 18px;
        letter-spacing: 1px;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        color: black;
       margin-top: 10px;
       margin-bottom: 3px;
      padding-left: 3px; 
      border-left-style: solid ;
      border-left-color: #eb9f73;
      background-color: #ffffff;
    }
    .Carousel{
      margin-left: 6px;
      margin-right: 6px;
      margin-top: 10px;
    }
    .coll-continer{
        margin-top: 10px;
       margin-bottom: 100px;
        scroll-behavior: smooth;
    }
    .coll-body{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .coll-card{
        width: 90%;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #D9D9D9;
        margin-top: 10px;
        padding: 5px;
    }
    .int-card{
        width: 90%;
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        margin-top: 10px;
        padding: 30px;
        border: solid #f67225 1px;
    }
    .all-coll-card{
        width: 90%;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color:#f67225;
        margin-top: 10px;
        padding: 5px;
        color: #fff;
    }
    .coll-card p{
        color: #000;
        margin-bottom: 0px;
        font-size: 13px;
        letter-spacing: 0.5px;
        font-family: var(--font);
    }
    .all-coll-card p{
        color: #fff;
        margin-bottom: 0px;
        font-size: 13px;
        letter-spacing: 0.5px;
        font-family: var(--font);
    }
   @media only screen and (min-width: 600px) {
    .tabbarrcontiner{
        display: none;
        }
        .fooder_continer{
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            background-image: linear-gradient(180deg,rgba(247, 241, 241, 0), rgb(245, 182, 122));
        }
       
   } 
  

   /* @media only screen and (min-width: 768px) {
    .tabbarrcontiner{
       display: none;
       }
       .fooder_continer{
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        background-image: linear-gradient(180deg,rgba(247, 241, 241, 0), rgb(245, 182, 122));
        background-image: linear-gradient(to right, rgba(247, 241, 241, 0), rgb(245, 182, 122));
    }
      
      
   } */